@font-face {
    font-family: Work-Sans;
    font-weight: 400;
    src: url(./App/_shared/assets/fonts/Work_Sans/static/WorkSans-Regular.ttf);
}
@font-face {
    font-family: Work-Sans;
    font-weight: 500;
    src: url(./App/_shared/assets/fonts/Work_Sans/static/WorkSans-Medium.ttf);
}
@font-face {
    font-family: Work-Sans;
    font-weight: 600;
    src: url(./App/_shared/assets/fonts/Work_Sans/static/WorkSans-SemiBold.ttf);
}
@font-face {
    font-family: Work-Sans;
    font-weight: 700;
    src: url(./App/_shared/assets/fonts/Work_Sans/static/WorkSans-Bold.ttf);
}
